import axios from '@/utils/request'

// 获取验证码
export const pcGetCode = (data) => {
    return axios.request({
        url: '/api/h5-enterprise/login/smsCode',
        data,
        method: 'get'
    })
}

// 重置密码
export const resetPasswrod = (data) => {
    return axios.request({
        url: '/api/h5-enterprise/login/resetPasswrod',
        data,
        method: 'post'
    })
}

